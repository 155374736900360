import CashPaid from "./components/CashPaid";
import Navbar from "./components/Navbar";
import Paid from "./components/Paid";
import Insurance from "./components/Insurance";
import Queue from "./components/Queue";
import Balance from "./components/Balance";
import PatientsChart from "./components/PatientsChart";
import Requests from "./components/Requests";
import RecentRequests from "./components/RecentRequests";
import { useEffect, useState } from "react";
import useAxios from "../../../../Hooks/useAxios";

import usePouch from "./../../../../Hooks/usePouch";
import useApp from "../../../../Hooks/useApp";

const Summary = () => {
  const [paid, setPaid] = useState(0);
  const [balance, setBalance] = useState(0);
  const [insurance, setInsurance] = useState(0);
  const [cash_paid, setCashPaid] = useState(0);
  const [requests, setRequests] = useState("");
  const [recentRequests, setRecentRequests] = useState([]);
  const [mVisits, setMVisits] = useState([]);
  const { user } = useApp();

  const { findAll2 } = usePouch();

  const request = useAxios();

  async function getInitialData() {
    let res = await request({
      method: "GET",
      url: "hospital/analytics/billing",
      params: {
        user_id: parseInt(user.id),
        year: new Date().getFullYear(),
      },
    });

    if (res === "error") {
      return;
    }

    setRecentRequests(res.recent_requests);
    setRequests(res.requests);
    setBalance(res.amount.balance);
    setInsurance(res.amount.insurance);
    setPaid(res.amount.paid);
    setMVisits(res.patients_monthly);
    setCashPaid(res.amount.cash_paid);
  }

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div className="w-full  bg-ghost_white">
      <div className="h-full px-10 ">
        <Navbar />
        <div className="flex justify-between lg:gap-x-10 mb-10 pt-8">
          <Paid paid={paid} />
          <Balance balance={balance} />
          <CashPaid cash_paid={cash_paid} />
          <Insurance insurance={insurance} />
        </div>
        <div className="flex justify-between mb-10 w-full">
          <div className="w-7/12">
            <PatientsChart mVisits={mVisits} />
          </div>
          <div className="5/12 mx-auto">
            <Requests requests={requests} />
          </div>
        </div>
        <div className="flex justify-between lg:gap-x-10 mb-10 w-full">
          <div className="w-7/12">
            <Queue />
          </div>
          <div className="w-5/12">
            <RecentRequests recentRequests={recentRequests} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
