import NumberInput from "../../../../../../Components/Inputs/NumberInput";
import TextBox from "../../../../../../Components/Inputs/TextBox"
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";
import {useRef, useState} from 'react'
import useHandleFormError from "../../../../../../Hooks/useHandleFormError";
import useApp from "../../../../../../Hooks/useApp";
import useAxios from "../../../../../../Hooks/useAxios";
import usePouchCud from "../../../../Hooks/usePouchCud";
import { useNavigate, useParams } from "react-router-dom";

const MoreVitals =()=>{
    const input = useRef({ form: {}, err: {} });

    const [showError, setShowError] = useState(0);

    const formErrors = useHandleFormError();

    const { setModalMessage, setShowFormModal, setShowSuccessModal } = useApp();

    const {user} = useApp();

    const navigate = useNavigate()

    const { visit_id } = useParams();

    const { create } = usePouchCud()

    const request = useAxios();

    const [vitals, setVitals] = useState({
        height:"",
        weight:"",
        temparature:"",
        diastolic_bp:"",
        systolic_bp:"",
        blood_preasure:"",
        respiratory_rate:"",
        blood_sugar:"",
        comments:""
    })

    async function submit () {
        setShowError(showError + 1);

        if (formErrors({input})) return;

        let datas = {
            height : parseInt(input.current.form.height),
            weight: parseInt(input.current.form.weight),
            temperature : parseInt(input.current.form.temperature),
            diastolic_bp: parseInt(input.current.form.diastolic_bp),
            systolic_bp : parseInt(input.current.form.systolic_bp),
            blood_preasure : parseInt(input.current.form.blood_preasure),
            respiratory_rate : parseInt(input.current.form.respiratory_rate),
            blood_sugar : parseInt(input.current.form.blood_sugar),
            comments : input.current.form.comments, 
        }

        let res  = await request({
            method: "POST",
            url: "hospital/vitals",
            data: {
                visit_id: parseInt(visit_id),
                user_id: parseInt(user.id),
                datas
            }
        })

        if (res !== "error") {
            await create({ name: "visit_vitals", doc: res });
            await setModalMessage("Patient Vital Added");
            await setShowSuccessModal(true);
            navigate(`/dashboard/triage-queue`)
        }
       await setVitals(res);
       

    }

    return(
        <div className="w-[60%] mx-auto h-[400px] bg-white shadow-md rounded-lg px-5 mt-5 mb-4">
            <div className="p-4 grid grid-cols-3 gap-x-6">

                <NumberInput
                    initial={vitals.systolic_bp }
                    input={input}
                    showError={showError}
                    inputKey="systolic_bp"
                    mt={0.5}
                    config={{
                        required: false,
                        label: "Systolic B.P",
                        placeholder: "",
                        max: 99999999999,
                        min: 0,
                    }}
                />

                <NumberInput
                    initial={vitals.diastolic_bp }
                    input={input}
                    showError={showError}
                    inputKey="diastolic_bp"
                    mt={0.5}
                    config={{
                        required: false,
                        label: "Diastolic B.P",
                        max: 99999999999,
                        min: 0,
                    }}
                />

                <NumberInput
                    initial={vitals.weight}
                    input={input}
                    showError={showError}
                    inputKey="weight"
                    mt={0.5}
                    config={{
                        required: false,
                        label: "Weight(kg)",
                        max: 99999999999,
                        min: 0,
                    }}
                />
                       
                <NumberInput
                    initial={vitals.height}
                    input={input}
                    showError={showError}
                    inputKey="height"
                    mt={0.5}
                    config={{
                        required: false,
                        label: "Height (cm)",
                        max: 99999999999,
                        min: 0,
                    }}
            />
                <NumberInput
                    initial={vitals.temparature}
                    input={input}
                    showError={showError}
                    inputKey="temperature"
                    mt={0.5}
                    config={{
                        required: false,
                        label: "Temperature (°C)",
                        max: 99999999999,
                        min: 0,
                    }}
                />
               

                <NumberInput
                    initial={vitals.blood_preasure}
                    input={input}
                    showError={showError}
                    inputKey="blood_preasure"
                    mt={0.5}
                    config={{
                        required: false,
                        label: "Blood Pressure (B.P)",
                        max: 99999999999,
                        min: 0,
                    }}
                />
                                    
                <NumberInput
                    initial={vitals.respiratory_rate}
                    input={input}
                    showError={showError}
                    inputKey="respiratory_rate"
                    mt={0.5}
                    config={{
                        required: false,
                        label: "Respiratory Rate",
                        max: 99999999999,
                        min: 0,
                    }}
                />
                
                <NumberInput
                    initial={vitals.blood_sugar}
                    input={input}
                    showError={showError}
                    inputKey="blood_sugar"
                    mt={0.5}
                    config={{
                        required: false,
                        label: "Blood sugar",
                        max: 99999999999,
                        min: 0,
                    }}
                />

                <TextBox 
                    input={input}
                    inputKey="comments"
                    initial = {vitals.comments}
                    showError = {showError}
                    config = {{
                        disabled: false,
                        required: false,
                        label: "Comments",
                        placeholder: "Enter Comment",
                        minChar: 0,
                        maxChar: 200,
                        cols: 6,
                        
                        }}
                />
                         
                
            </div>
            <div className="block my-3">
                <BtnRounded click={() => submit()} >
                    Submit
                </BtnRounded>
            </div>
                
                
        </div>

    )
};

export default MoreVitals;