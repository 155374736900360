import React from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../Util/Time";
import TableActions from "./TableActions";
import Badge from "../../../../../../Components/Badges/Badge";

const TRow = ({ doc }) => {
  return (
    <>
      <TableRow key={doc?.id || ""}>
        <TableCell w={90 / 7}>
          <TableText
            label={` ${doc?.consumables?.inventory_items?.name || ""}`}
          />
        </TableCell>

        <TableCell w={90 / 7}>
          {doc?.prescription_status == "pending" ? (
            <Badge label={doc?.prescription_status} bg={"pale_spring_bud"} />
          ) : doc?.prescription_status == "canceled" ? (
            <Badge label={doc?.prescription_status} bg={"pale_pink"} />
          ) : doc?.prescription_status == "complete" ? (
            <Badge label={doc?.prescription_status} bg={"bright_grey"} />
          ) : (
            " "
          )}
        </TableCell>
        <TableCell w={90 / 7}>
          <TableText label={`amount: ${doc?.amount}`} />
          <TableText label={`qty ${doc?.qty || 1}`} />
          <TableText label={`total: ${doc.to_pay}`} />
        </TableCell>
        <TableCell w={90 / 7}>
          <TableText label={doc.payment_method} />
        </TableCell>
        <TableCell w={90 / 7}>
          <TableText label={doc.dosage} />
        </TableCell>
        <TableCell w={90 / 7}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell>
          <TableActions doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
