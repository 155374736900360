import { TableRow, TableCell, TableText } from "../../../../../../../Components/FinalTable";
import TableActions from "./TableActions";

const TRow=({ doc })=>{
    
    return(
        <TableRow >
            <TableCell w={100 / 3.5}>
                <TableText label={doc.alergy_type}/>
            </TableCell>
            <TableCell w={100 / 3.5}>
                <TableText label={doc.alergen}/>
            </TableCell>
            <TableCell w={100 / 4}>
                <TableText label={doc.reaction}/>
            </TableCell>
            <TableCell w={100 / 4}>
                <TableText />
            </TableCell>
            <TableCell w={100 / 4}>
                <TableText />
            </TableCell>
            <TableCell w={100 / 4}>
                <TableText />
            </TableCell>
            <TableCell w={100 / 6}>
            <TableActions doc={doc} />
            </TableCell>
        </TableRow>
             
    )
};

export default TRow;
