import React from 'react'
import { AiOutlineInsurance } from 'react-icons/ai'
import { HiOutlineSwitchVertical } from 'react-icons/hi'

export default function Insurance({insurance}) {
    return (
        <div className="w-80 h-40 shadow-sm rounded-2xl bg-primary p-6">
            <div className="flex justify-between items-center mb-10 ">
                <div className="font-DM-Reg text-xl text-white">
                    <p>Insurance Amount</p>
                </div>
                <div>
                    <AiOutlineInsurance
                        size="28px"
                        className="text-white"
                    />
                </div>
            </div>
            <div className="flex justify-between items-center my-10 ">
                <div className="font-pop-reg text-4xl text-white">
                    <p>{insurance}</p>
                </div>
                <div className="flex justify-center items-center ">
                    
                </div>
            </div>
        </div>
    )
}
