import React from 'react'
import { FiCalendar } from 'react-icons/fi'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { convDate } from '../../../../../Util/Time'

const RecentRequests = ({recentRequests}) => {

    function statusColor(status){
        if(status === 'approved'){
            return 'bg-green-100  text-gray-600'
        } else if (status === 'canceled'){
            return 'bg-red  text-white font-bold'
        } else {
            return 'bg-yellow-100  text-gray-600'
        }
    }

  return (
    <div className='w-10/12 p-3  bg-white rounded-2xl flex flex-col '>
    <div className="flex justify-between items-center ">
        <h1 className="font-bold text-lg px-6 py-3">Recent Requests</h1>
    </div>
    <div className='flex justify-center' >
        <div className="flex w-11/12 flex-col justify-center text-xs text-gray-500 mt-10 mr-2">
        
            {recentRequests.map( recent => (
                <div key={recent.id}>
                    <div className="flex justify-between my-3">
                        <div className="flex flex-col">
                            <h1 className="font-bold text-gray-800 mb-1 text-sm">{recent.is_inventory ? recent.inventory_items.name : recent.fixed_assets.name }</h1>
                            <div ><FiCalendar className='inline-block' /> {recent.is_inventory ? convDate(recent.inventory_items.created_at) : convDate(recent.fixed_assets.created_at) }</div>
                        </div>
                        <div className={`py-1 px-6  text text-sm rounded-lg text-center my-auto ${statusColor(recent.status)} capitalize `}>
                            {recent.status}
                        </div>
                    </div>
            <hr className='my-2' />
                </div>
            ))}

        </div>
    </div>
</div>
  )
}

export default RecentRequests