import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import BtnPlain from '../../../../../../Components/Buttons/BtnPlain'
import { ActionBtn } from '../../../../../../Components/FinalTable'
import useAxios from '../../../../../../Hooks/useAxios';
import useApp from '../../../../../../Hooks/useApp';
import usePouchCud from '../../../../Hooks/usePouchCud';
import TableContext from './TContext';


const TableActions = ({ doc}) => {

    const navigate = useNavigate();
    const request = useAxios()
    const { setShowSuccessModal, setModalMessage } = useApp();
    const {data, setData} = useContext(TableContext)
    const [editModal, setShowEditModal] = useState(false)
    const {deleteDoc} = usePouchCud()

    const handleDelete = async () => {
        let res = await request({
            method: "DELETE",
            url: `hospital/visit/investigations/${doc.id}`
          });
      
          if (res !== "error") {
            
            setModalMessage("Investigation Deleted!");
            setShowSuccessModal(true);
            await deleteDoc({name: 'visit_investigations', doc:res})
          }
          let newDocs = data.filter((row) => (row.id === doc.id ? false : true));
    setData(newDocs);
    }

    return (
        <div className="flex-1">
            <ActionBtn next={() => navigate(`view/${doc.id}`)} showNext={false} showOptions={false}>
                {/* <div className=" gap-y-2 flex flex-col text-sm">
                    
                        <BtnPlain
                        activeBgColor="white"
                        bg="white"
                        click={() => setShowComments(true)}
                    >
                        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                            <p className="  text-primary">
                                <FaPlus />
                            </p>
                            <p className=" ">Add Comments</p>
                        </div>
                    </BtnPlain>
                    
                </div> */}
            </ActionBtn>
        </div>
    )
}

export default TableActions