import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import BtnPlain from '../../../../../../Components/Buttons/BtnPlain'
import { ActionBtn } from '../../../../../../Components/FinalTable'

const TableActions = ({ doc}) => {
    const navigate = useNavigate();


    return (
        <div className="flex-1">
            
            <ActionBtn next={() => navigate(`view/${doc.id}`)} showNext={false} showOptions={false}>
                <div className=" gap-y-2 flex flex-col text-sm">
                        {/* <BtnPlain
                        activeBgColor="white"
                        bg="white"
                        click={() => setShowComments(true)}
                    >
                        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                            <p className="  text-primary">
                                <FaPlus />
                            </p>
                            <p className=" ">Add Comments</p>
                        </div>
                    </BtnPlain> */}
                    
                   
                </div>
            </ActionBtn>
        </div>
    )
}

export default TableActions