import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import BtnPlain from '../../../../../../Components/Buttons/BtnPlain'
import { ActionBtn } from '../../../../../../Components/FinalTable'
import { FiEdit2, FiFileText, FiFolderPlus } from "react-icons/fi";
import useAxios from '../../../../../../Hooks/useAxios';
import useApp from '../../../../../../Hooks/useApp';
import { TbNurse } from 'react-icons/tb'
import usePouchCud from '../../../../Hooks/usePouchCud';
import { FaPlus, FaRegFile, FaRegTrashAlt } from 'react-icons/fa';
import TableContext from './TContext';



const TableActions = ({ doc}) => {
    const [showComments, setShowComments] = useState(false)
    const [showDocuments, setShowDocuments] = useState(false)
    const [showPerform, setShowPerform] = useState(false)
    const [showViewComments, setshowViewComments] = useState(false)
    const [showViewDocuments, setShowViewDocuments] = useState(false)
    const navigate = useNavigate();
    const request = useAxios()
    const { setShowSuccessModal, setModalMessage, user } = useApp();
    const {data, setData} = useContext(TableContext)
    const [editModal, setShowEditModal] = useState(false)
    const {deleteDoc, update} = usePouchCud()
    const [currState, setCurrState] = useState("")

  
    const handleDelete = async () => {
        let res = await request({
            method: "DELETE",
            url: `hospital/visit/procedure/${doc.id}`
          });
      
          if (res !== "error") {
            
            setModalMessage("Procedure Deleted!");
            setShowSuccessModal(true);
            await deleteDoc({name: 'visit_procedure', doc:res})
          }
          let newDocs = data.filter((row) => (row.id === doc.id ? false : true));
    setData(newDocs);
    }

    
    return (
        <div className="flex-1">
            {/* <PerformProcedure doc={doc} state={currState} showModal={showPerform} setShowModal={setShowPerform}  />
            <VisitComments doc={doc} showModal={showComments} setShowModal={setShowComments} />
            <VisitDocuments doc={doc} showModal={showDocuments} setShowModal={setShowDocuments} thisUser={parseInt(user.id)} />
            <ViewComments doc={doc} showModal={showViewComments} setShowModal={setshowViewComments} />
            <ViewDocuments doc={doc} showModal={showViewDocuments} setShowModal={setShowViewDocuments}  /> */}
            {/* <ActionBtn next={() => navigate(`view/${doc.id}`)} showNext={false}>
                <div className=" gap-y-2 flex flex-col text-sm">
                
                        <BtnPlain
                        activeBgColor="white"
                        bg="white"
                        click={() => setShowComments(true)}
                    >
                        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                            <p className="  text-primary">
                                <FaPlus />
                            </p>
                            <p className=" ">Add Comments</p>
                        </div>
                    </BtnPlain>
                    <BtnPlain
                        activeBgColor="white"
                        bg="white"
                        click={() => setShowDocuments(true)}
                    >
                        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                            <p className="  text-primary">
                                <FiFolderPlus />
                            </p>
                            <p className=" ">Add Documents</p>
                        </div>
                    </BtnPlain>
                    <BtnPlain
                        activeBgColor="white"
                        bg="white"
                        click={() => setshowViewComments(true)}
                    >
                        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                            <p className="  text-primary">
                                <FiFileText />
                            </p>
                            <p className=" ">View Comments</p>
                        </div>
                    </BtnPlain>
                    <BtnPlain
                        activeBgColor="white"
                        bg="white"
                        click={() => setShowViewDocuments(true)}
                    >
                        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                            <p className="  text-primary">
                                <FaRegFile />
                            </p>
                            <p className=" ">View Documents</p>
                        </div>
                    </BtnPlain>
                    {currState == 'paid' ? (<>
                        <BtnPlain
                        activeBgColor="white"
                        bg="white"
                        click={performProcedure}
                    >
                        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                            <p className="  text-red">
                                <TbNurse />
                            </p>
                            <p className=" ">Perform Procedure</p>
                        </div>
                    </BtnPlain>
                    </>) : (<>
                        <BtnPlain
                        activeBgColor="white"
                        bg="white"
                        click={() => setShowPerform(true)}
                    >
                        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                            <p className="  text-red">
                                <TbNurse />
                            </p>
                            <p className=" ">Perform Procedure</p>
                        </div>
                    </BtnPlain>
                    </>) }
                    {doc.procedure_status == 'pending' && (<>
                        <BtnPlain
                        activeBgColor="white"
                        bg="white"
                        click={handleDelete}
                    >
                        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                            <p className="  text-red">
                                <FaRegTrashAlt />
                            </p>
                            <p className=" ">Delete Procedure</p>
                        </div>
                    </BtnPlain>
                    </>) }
                    
                </div>
            </ActionBtn> */}
        </div>
    )
}

export default TableActions