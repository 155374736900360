import Tooltip from "../../../../Components/ToolTip";
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import {  FaMoneyBill } from "react-icons/fa";

const PaymentCollectionTip = ({ open = false, active = false, setShowPaymentTip }) => {

  const id = "payment-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded
        
        click={() => setShowPaymentTip(true)}
      >
        <FaMoneyBill />
      </BtnRounded>
      <Tooltip
        id={id}
        txt1="Collect Payment"
        txt2=""
      >
        <FaMoneyBill />
      </Tooltip>
    </div>
  );
};

export default PaymentCollectionTip;
