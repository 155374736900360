import React, { useState, useEffect } from "react";

import FormScreen from "../../../../Components/Templates/FormScreen";
import FormLable from "../../../../Components/Label/FormLabel";
import Screen from "./Components/Screen";
import Steps from "./Components/Steps";
import usePouch from "../../../../Hooks/usePouch";
import { useNavigate, useParams } from "react-router-dom";
import useFeatures from "../../Hooks/useFeatures";


const Attend = () => {
  const { syncTables } = useFeatures();
  const navigate = useNavigate();
  const { fetchById } = usePouch();
  const [step, setStep] = useState(-1);
  const [vis, setVis] = useState();
  const [patient, setPatient] = useState({});
  const { visit_id } = useParams();
  const [pagename, setPagename] = useState("...")
 
  function handleBack() {
    setStep((c) => c - 1);
  }

  useEffect(() => {
    async function fetchPatient() {
      let vs = await fetchById({
        db: "visits",
        id: visit_id.toString(),
      });

      let pt = await fetchById({
        db: "patients",
        id: vs.patient_id.toString(),
      });

      if(vs === "error" || pt === "error"){
        navigate("/dashboard/user-queue")
      }

      setPatient(pt);
      setVis(vs);
    }
    fetchPatient();
  }, []);


  useEffect(() => {
   function getPagename() {
    try {
      if (step === -1) {
        pagename = "Allergies"
      }
      pagename = "Vitals"
      
    } catch (error) {
      
    }
   }
    getPagename()
  }, [step])

 
  useEffect(() => {
    syncTables([
      "visits",
      "patient_allergies",
      "patients",
      "visit_vitals",
      "visit_steps",
    ]);
  }, []);

  return (
    <FormScreen backBtn={true} back={() => handleBack()}>
      <div className="bg-ghost-white shadow-md py-2">
        <FormLable
          label={`${patient.first_name} ${patient.second_name}`}
          mt={1}
          mb={1}
        />
      </div>
      <div className="mt-4 pt-4">
        <Steps
          currentStep={step}
          close={() => navigate("/dashboard/triage-queue")}
        />
      </div>
      <div className="w-full">
        <Screen step={step} setStep={setStep} />
      </div>
    </FormScreen>
  );
};

export default Attend;
