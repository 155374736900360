import React, { useEffect, useRef, useState } from "react";
import BasicModal from "../../../../../../Components/Modals/BasicModal";
import TextInput from "../../../../../../Components/Inputs/TextInput";
import usePouch from "../../../../../../Hooks/usePouch";

import NumberInput from "../../../../../../Components/Inputs/NumberInput";

import TextArea from "../../../../../../Components/Inputs/TextBox";

import useApp from "../../../../../../Hooks/useApp";

import useAxios from "../../../../../../Hooks/useAxios";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import BtnBlock from "../../../../../../Components/Buttons/BtnBlock";

import { IoMdArrowForward } from "react-icons/io";
import usePouchCud from "../../../../Hooks/usePouchCud";
import { useParams } from "react-router-dom";
import { toTZS } from "../../../../../../Util/Currency";

const PaymentCollection = ({
  showModal,
  setShowModal,
  doc,
  bills,
  data,
  getDetails,
}) => {
  const [showError, setShowError] = useState(0);

  const input = useRef({ form: {}, err: {} });

  const request = useAxios();

  const formErrors = useHandleFormError();

  const { id } = useParams();

  const { user, setModalMessage, setShowSuccessModal } = useApp();

  const { update } = usePouchCud();

  async function submit() {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let obj = {
      bill_id: parseInt(bills.id),
      amount_paid: parseInt(input.current.form.amount_paid),
      discount: input.current.form.discount
        ? parseInt(input.current.form.discount)
        : parseInt(0),
    };

    let res = await request({
      method: "PUT",
      url: "hospital/visit/bill",
      data: obj,
    });

    if (res === "error") return;
    setModalMessage("Payment Received!");
    setShowSuccessModal(true);
    getDetails();
    await update({ name: "bills", doc: res });
    setShowModal(false);
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Payment Collection
        </h2>
        <p className="font-DM-Reg text-lg text-black text-start">
          TOTAL: {toTZS(bills.total)}
        </p>
        <p className="font-DM-Reg text-lg text-black text-start">
          AMOUNT PAID: {toTZS(bills.amount_paid)}
        </p>
        <p className="font-DM-Reg text-lg text-black text-start">
          BALANCE: {toTZS(bills.total - bills.amount_paid)}
        </p>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <NumberInput
            config={{
              disabled: false,
              label: "Amount ",
              max: 999999999999,
              min: 0,
              placeholder: "Amount",
              required: true,
            }}
            mt={0.5}
            showError={showError}
            input={input}
            inputKey="amount_paid"
          />
          <NumberInput
            config={{
              disabled: false,
              label: "Discount",
              max: 999999999999,
              min: 0,
              placeholder: "Discount",
              required: false,
            }}
            mt={0.5}
            showError={showError}
            input={input}
            inputKey="discount"
          />
          <BtnBlock click={submit} mt={2.5} bg="primary" textColor="white">
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Receive Payment</span>
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default PaymentCollection;
