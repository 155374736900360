import { useRef, useState, useEffect } from "react";

import BasicModal from "../../../../../../../Components/Modals/BasicModal";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";

import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";

import TextInput from "./../../../../../../../Components/Inputs/TextInput";
import usePouchCud from "../../../../../Hooks/usePouchCud";
import useApp from "../../../../../../../Hooks/useApp";
import TContext from "../All/TContext";
import AllContext from "../context/AllContext";
import usePouch from "../../../../../../../Hooks/usePouch";
import useAxios from "../../../../../../../Hooks/useAxios";
import { useParams } from "react-router-dom";
import { useContext } from "react";

const EditAllergiesModal = ({ showModal = false, setShowModal, doc }) => {
  const input = useRef({ form: {}, err: {} });

  const [showError, setShowError] = useState(0);

  const request = useAxios();

  const { update } = usePouchCud();

  const { data, setData } = useContext(AllContext);

  const { fetchById } = usePouch();

  const { setModalMessage, setShowSuccessModal } = useApp();

  const [show, setShow] = useState(false);

  const [allergy, setAllergy] = useState({
    alergy_type: "",
    alergen: "",
    reaction: "",
  });

  useEffect(() => {
    async function initialize() {
      try {
        let all = await fetchById({
          db: "patient_allergies",
          id: doc.id.toString(),
        });
        setAllergy(all);
        setShow(true);
      } catch (error) {}
    }
    initialize();
  }, []);

  const formErrors = useHandleFormError();

  async function submit() {
    await setShowError(showError + 1);

    if (formErrors({ input })) return;

    let res = await request({
      method: "PUT",
      url: "patient/allergy",
      data: {
        id: parseInt(doc.id),
        alergy_type: input.current.form.alergy_type,
        alergen: input.current.form.alergen,
        reaction: input.current.form.reaction,
      },
    });

    if (res !== "error") {
      await setModalMessage("Patient Allergy Updated");
      await setShowSuccessModal(true);
      await update({ name: "patient_allergies", doc: res });

      let newData = data.map((datum) => (datum.id == res.id ? res : datum));
      setData(newData);
    }

    await setShowModal(false);
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col p-5 gap-y-8 p-3" style={{ width: "30em" }}>
        <div style={{ marginTop: "-2.5em" }}>
          <TextInput
            initial={allergy.alergy_type}
            input={input}
            showError={showError}
            inputKey="alergy_type"
            mt={0.5}
            config={{
              required: true,
              label: "Allergy Type",
              placeholder: "",
              maxChar: 250,
              minChar: 3,
            }}
          />
          <TextInput
            initial={allergy.alergen}
            input={input}
            showError={showError}
            inputKey="alergen"
            mt={0.5}
            config={{
              required: true,
              label: "Allergen",
              placeholder: "",
              maxChar: 250,
              minChar: 3,
            }}
          />
          <TextInput
            initial={allergy.reaction}
            input={input}
            showError={showError}
            inputKey="reaction"
            mt={0.5}
            config={{
              required: true,
              label: "Reaction",
              placeholder: "",
              maxChar: 250,
              minChar: 3,
            }}
          />
        </div>
        <BtnRounded click={() => submit()}>Save</BtnRounded>
      </div>
    </BasicModal>
  );
};

export default EditAllergiesModal;
