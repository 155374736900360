import Allergies from "../Pages/Allergies/index";

import VitalsForm from "../Pages/Vitals/VitalsForm";

import MoreVitals from "../Pages/Vitals/MoreVitals"

const Screen = ({ step, setStep }) => {
  if (step === -1) {
    return <Allergies setStep={setStep} />;
  }
  // if (step === 0) {
  //   return <VitalsForm setStep={setStep} />;
  // }
  if (step === 0) {
    return <MoreVitals setStep={setStep} />;
  }
  return null;
};

export default Screen;
