import { useEffect, useState } from "react";
import BellTopBar from "../../../../Components/Bars/BellTopBar";
import useAxios from "../../../../Hooks/useAxios";
import { useParams } from "react-router-dom";
import useApp from "../../../../Hooks/useApp";
import BillingDetails from "./components/BillingDetails";

const Edit = () => {
  const [patient, setPatient] = useState({});
  const request = useAxios();
  const { id } = useParams();
  const { user, setIsLoading } = useApp();
  const [billingDetails, setBillingDetails] = useState({});
  const [bills, setBills] = useState({});
  const [consultations, setConsultations] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [investigations, setInvestigations] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [othercharges, setOthercharges] = useState([]);
  const [stepComments, setStepComments] = useState("");
  const [commenter, setCommenter] = useState("");

  async function getDetails() {
    try {
      setIsLoading(true);
      let res = await request({
        method: "GET",
        url: `hospital/visit/bill?visit_step_id=${id}`,
      });

      setPatient(res?.visit?.patients || {});
      setBillingDetails(res || {});
      setBills(res?.bill || {});
      setConsultations(res?.consultations || []);
      setInvestigations(res?.investigations || []);
      setOthercharges(res?.other_charges || []);
      setPrescriptions(res?.prescriptions || []);
      setProcedures(res?.procedures || []);
      setStepComments(res?.visit_step?.comments || "");
      setCommenter(
        res?.visit_step?.team_visit_steps_step_created_byToteam?.name || ""
      );
    } catch (error) {
      //console.log(error)
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className="w-full h-full bg-ghost_white">
      <div className="mb-4 pb-4">
        <BellTopBar
          label={`${patient.first_name} ${patient.second_name} - Billing Form`}
        />
      </div>

      <div className=" ml-5 mt-5 p-5 mb-4 mr-5 bg-white rounded-lg shadow-md ">
        <BillingDetails
          patient={patient}
          setPatient={setPatient}
          billingDetails={billingDetails}
          bills={bills}
          commenter={commenter}
          consultations={consultations}
          prescriptions={prescriptions}
          investigations={investigations}
          procedures={procedures}
          othercharges={othercharges}
          stepComments={stepComments}
          getDetails={getDetails}
        />
      </div>
    </div>
  );
};

export default Edit;
