import React, { useEffect, useState } from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../Components/FinalTable";

import usePouch from "../../../../../../Hooks/usePouch";

import { convDate } from "../../../../../../Util/Time";
import TableActions from "./TableActions";

import Badge from "../../../../../../Components/Badges/Badge";

const TRow = ({ doc }) => {
  return (
    <>
      <TableRow key={doc?.id}>
        <TableCell w={90 / 7}>
          <TableText label={convDate(doc?.created_at)} />
        </TableCell>
        <TableCell w={90 / 7}>
          {doc?.investigation_status == "pending" ? (
            <Badge label={doc?.investigation_status} bg={"pale_spring_bud"} />
          ) : doc?.investigation_status == "canceled" ? (
            <Badge label={doc?.investigation_status} bg={"pale_pink"} />
          ) : doc?.investigation_status == "complete" ? (
            <Badge label={doc?.investigation_status} bg={"bright_grey"} />
          ) : (
            " "
          )}
        </TableCell>
        <TableCell w={90 / 7}>
          <TableText label={doc?.investigations?.name || "---"} />
        </TableCell>
        <TableCell w={90 / 7}>
          <TableText label={doc?.amount || ""} />
        </TableCell>
        <TableCell w={90 / 7}>
          <TableText label={doc?.payment_method || ""} />
        </TableCell>
        <TableCell w={90 / 7}>
          <TableText label={doc?.paid === false ? "NO" : "YES"} />
        </TableCell>
        <TableCell w={90 / 7}>
          <TableText label={doc?.to_pay || ""} />
        </TableCell>
        <TableCell>
          <TableActions doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
