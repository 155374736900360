import React, { useEffect, useRef, useState } from "react";
import BasicModal from "../../../../../../Components/Modals/BasicModal";

import TextInput from "../../../../../../Components/Inputs/TextInput";
import NumberInput from "../../../../../../Components/Inputs/NumberInput";
import TextArea from "../../../../../../Components/Inputs/TextBox";

import useAxios from "../../../../../../Hooks/useAxios";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import BtnBlock from "../../../../../../Components/Buttons/BtnBlock";

import { useParams } from "react-router-dom";

import useApp from "../../../../../../Hooks/useApp";

const AddCharge = ({ showModal, setShowModal, getDetails, bills }) => {
  const [showError, setShowError] = useState(0);

  const input = useRef({ form: {}, err: {} });

  const request = useAxios();

  const formErrors = useHandleFormError();

  const { id } = useParams();

  const { user } = useApp();

  async function submit() {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    console.log(input.current.form);

    const doc = input?.current?.form || {};

    console.log(bills);

    const obj = {
      ...doc,
      visit_step_id: parseInt(id),
      requested_by: parseInt(user.id),
    };

    console.log(obj);

    const res = await request({
      method: "POST",
      url: "hospital/visit/other-charge",
      data: obj,
    });

    if (res === "error") return;

    setShowModal(false);
    getDetails();
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className=" flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Payment Collection
        </h2>
        <div className="lg:w-[517px] flex flex-col gap-y-6">
          <TextInput
            input={input}
            inputKey={"name"}
            initial=""
            showError={showError}
            config={{ required: true, label: "Name", minChar: 0 }}
          />
          <NumberInput
            initial={1}
            config={{
              disabled: false,
              label: "Amount",
              max: 999999999999,
              min: 1,
              placeholder: "2999",
              required: true,
            }}
            mt={0.5}
            showError={showError}
            input={input}
            inputKey="to_pay"
          />

          <TextArea
            input={input}
            inputKey={"description"}
            initial=""
            config={{
              required: false,
              label: "Description",
            }}
          />
          <BtnBlock click={submit} mt={2.5} bg="primary" textColor="white">
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Receive Payment</span>
            </div>
          </BtnBlock>
        </div>
      </div>
    </BasicModal>
  );
};

export default AddCharge;
