import React, { useEffect, useState, useContext } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../Util/Time";

import Badge from "../../../../../../Components/Badges/Badge";

import TActions from "./TActions";

const TRow = ({ doc }) => {
  return (
    <TableRow>
      <TableCell w={95 / 8}>
        <TableText label={`vis-${doc?.visit_steps?.visits?.id || ""}`} />
      </TableCell>
      <TableCell w={95 / 8}>
        <TableText label={convDate(doc?.visit_steps?.visits?.created_at)} />
      </TableCell>
      <TableCell w={95 / 8}>
        <TableText
          label={`${doc?.visit_steps?.visits?.patients?.first_name || ""} ${
            doc?.visit_steps?.visits?.patients?.second_name || ""
          }`}
        />
      </TableCell>
      <TableCell w={95 / 8}>
        <Badge
          label={doc?.user_id === null ? "Waiting" : "Assigned"}
          bg={doc?.user_id === null ? "bright_grey" : "pale_pink"}
        />
      </TableCell>

      <TableCell w={95 / 8}>
        <Badge
          label={doc?.is_attending ? "YES" : "NO"}
          bg={doc?.is_attending ? "bright_grey" : "pale_pink"}
        />
      </TableCell>

      <TableCell w={95 / 8}>
        <Badge
          label={doc?.visit_steps.visits ? "High" : "Normal"}
          bg={doc?.visit_steps.visits ? "pale_pink" : "bright_grey"}
        />
      </TableCell>
      <TableCell w={95 / 8}>
        <Badge label={doc?.spaces?.name || ""} bg={"bright_grey"} />
      </TableCell>
      <div style={{ width: "5%" }} className=" ">
        <TActions doc={doc} visit={doc?.visit_steps?.visits || {}} />
      </div>
    </TableRow>
  );
};

export default TRow;
