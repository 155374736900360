import React, { useEffect, useState } from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../Components/FinalTable";

import usePouch from "../../../../../../Hooks/usePouch";

import { convDate } from "../../../../../../Util/Time";
import TableActions from "./TableActions";

const TRow = ({ doc }) => {
  const [reqBy, setReqBy] = useState("___");
  const [paymentM, setPaymentM] = useState({});

  const { fetchById } = usePouch();

  useEffect(() => {
    async function Initialize() {
      try {
        let req = await fetchById({
          db: "team",
          id: doc.requested_by.toString(),
        });
        setReqBy(req);
      } catch (error) {
        //console.log(error)
      }
      try {
        let vst = await fetchById({
          db: "visits",
          id: doc.visit_id.toString(),
        });
        setPaymentM(vst);
      } catch (error) {
        //console.log(error);
      }
    }
    Initialize();
  }, [doc]);

  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={90 / 6}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={` ${doc.name}`} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={` ${doc.description}`} />
        </TableCell>

        <TableCell w={90 / 6}>
          <TableText label={doc?.to_pay} />
        </TableCell>
        <TableCell w={90 / 6}>
          <TableText label={doc?.team?.name} />
        </TableCell>
        <TableCell>
          <TableActions doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
