import React from "react";

import HeaderBtn from "../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import { TableHeaderRow } from "../../../../../../Components/FinalTable";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={90 / 7} label="DATE ADDED" />
        <HeaderBtn w={90 / 7} label="STATUS" />
        <HeaderBtn w={90 / 7} label="NAME" />
        <HeaderBtn w={90 / 7} label="AMOUNT" />
        <HeaderBtn w={90 / 7} label="PAYMENT METHOD" />
        <HeaderBtn w={90 / 7} label="PAID" />
        <HeaderBtn w={90 / 7} label="TO PAY" />
      </TableHeaderRow>
    </div>
  );
};

export default THead;
