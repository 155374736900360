import React, { useState, useEffect } from "react"
import { Table } from "../../../../../../../Components/FinalTable";
import TableHeading from "./TableHeading";
import TBody from "./TBody";
import TContext from "./TContext";
import TInfo from "./TInfo";
import usePouch from "../../../../../../../Hooks/usePouch";

const AllergiesTable = () => {
     
  return (
    <TContext.Provider
      value = {{ }}
      >
      <div className=" ml-5 p-5 mb-4 mr-4 ">
        <Table>
          <TableHeading />
          <TBody />
          <TInfo />
        </Table>
      </div>
    </TContext.Provider>
    
  );
};

export default AllergiesTable;
