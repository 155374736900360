import {  } from '../../../../../../../Components/FinalTable';

import TableHeader from '../../../../../../../Components/FinalTable/components/TableHeader';

import HeaderBtnSort from '../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort';

const TableHeading =()=>{
    return (
        <div>
            <TableHeader >
                <HeaderBtnSort w={90 / 6} label="ALLERGY TYPE"  />
                <HeaderBtnSort w={90 / 6} label="ALLERGEN"  />
                <HeaderBtnSort w={90 / 6} label="REACTION"  />
                <div className=" flex-1 invisible flex justify-center gap-x-4 text-3xl text-primary">
                    <span
                        className=" active:opacity-50 cursor-pointer text-red"
                        onClick={() => {}}
                    >
                        
                    </span>
                </div>
            </TableHeader>
        </div>
    )
}
export default TableHeading;