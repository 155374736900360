import React, { useRef, useContext, useState } from "react";

import FilterModal from "../../../../../../../Components/Modals/FilterModal";

import CheckBox from "../../../../../../../Components/Inputs/CheckBox";

import DatePicker from "../../../../../../../Components/Inputs/DatePicker";

import StateInput from "../../../../../../../Components/StateInput/SelectInput"

import {
  convDate2,
  toTimeStamp,
  isoDate,
} from "./../../../../../../../Util/Time";

import AllContext from "../../AllContext";


const TblFilter = ({ showFilter, setShowFilter }) => {
  const { setIsFiltered, handleFilterQuery } = useContext(AllContext);
  const [payment, setPayment] = useState("")
  const [st, setSt] = useState(convDate2(Date.now()));


  const input = useRef({form: {
    minDate: "",
    maxDate: "",
  },
  err: {},});

  async function applyFilter() {

    let filters = {}
    let to_filter = false


    if (payment !== "") {
      filters.filter_payment_method = payment
      to_filter = true;
    }

    if (payment !== "" ) {
      filters.filter_payment_method = payment
      to_filter = true;
    }

    if (input.current.form.minDate !== "") {
      filters.filter_start_date = isoDate(input.current.form.minDate)
      to_filter = true;
    }
    if (input.current.form.maxDate !== "" ) {
      filters.filter_end_date = isoDate(input.current.form.maxDate)
      to_filter = true;
    }

    if (to_filter) {
      handleFilterQuery(filters);
      setShowFilter(false); 
      return;
    }
   
    setShowFilter(false);
  }
  if (!showFilter) return null;

  return (
    <FilterModal
      showFilter={showFilter}
      setShowFilter={setShowFilter}
      apply={applyFilter}
    >
      <div>
        <h2 className=" font-DM-Bold text-xl my-3 text-cadet_grey">Payment</h2>
        <StateInput
          input={payment}
          setInput={setPayment}
          config={{
            required: false,
            firstDisabled: false,
            items: [
              { label: "None", value: "" },
              { label: "Cash", value: "Cash" },
              { label: "Insurance", value: "Insurance" },
            ],
            label: "Select Payment Method",
          }}
        />
      </div>

      <div className=" mt-4">
        <h2 className=" font-DM-Bold text-xl text-cadet_grey ">
          Check In Date
        </h2>
        <DatePicker
          initial={""}
          input={input}
          inputKey="minDate"
          mt={0}
          mb={0}
          config={{
            required: false,
            disabled: false,
            max: convDate2(Date.now()),
            label: "Start Date",
          }}
        />
        <DatePicker
          initial={''}
          input={input}
          inputKey="maxDate"
          mt={-2.5}
          mb={0}
          config={{
            required: false,
            disabled: false,
            max: convDate2(Date.now()),
            label: "End Date",
          }}
        />
      </div>
    </FilterModal>
  );
};

export default TblFilter;
