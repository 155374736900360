import React, { useEffect, useState } from "react";
import { Table } from "../../../../../../Components/FinalTable";
import usePouchPagination from "../../../../../../Hooks/usePouchPagination";
import TBody from "./TBody";
import THead from "./THeader";
import TInfo from "./TInfo";
import { useNavigate, useParams } from "react-router-dom";
import useFeatures from "../../../../Hooks/useFeatures";
import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";
import { FaPlus } from "react-icons/fa";
import TableContext from "./TContext";
import VisitNotes from "../Modals/VisitNotes";
import { toTZS } from "../../../../../../Util/Currency";

const OtherItemsTable = ({ othercharges, bills, addChargeClick }) => {
  const [limit, setLimit] = useState(30);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [showPagination, setShowPagination] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const navigate = useNavigate();
  // const { visit_notes, resetChanges } = useFeatures();

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  return (
    <TableContext.Provider
      value={{
        limit,
        setLimit,
        data,
        othercharges,
        setData,

        isFiltered,

        setIsFiltered,
        showPagination,
        setFixed,
      }}
    >
      <div className="flex flex-col  bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex flex-row justify-between w-full items-start">
          <p className="font-bold  text-lg uppercase text-BLACK">
            Other Charges Bill
          </p>
        </div>
        <div className=" flex w-full justify-end">
          <BtnPlain
            bg="primary"
            textColor="white"
            activeBgColor="secondary"
            mt={2}
            click={addChargeClick}
            className="btn"
          >
            <span className="px-2 font-light text-xl">
              <FaPlus />
            </span>
            <span className=" font-pop-reg">Add Charge</span>
          </BtnPlain>
        </div>
        <Table>
          <THead />
          <TBody />
          {/* <TInfo /> */}
        </Table>
        <div className="flex justify-end items-end">
          <p className="font-medium text-lg uppercase text-BLACK text-end my-5">
            Cumulative Amount: {toTZS(bills.other_charges_total)}
          </p>
        </div>
      </div>
    </TableContext.Provider>
  );
};

export default OtherItemsTable;
