import { useRef, useState, useEffect, useContext } from "react";

import BasicModal from "../../../../../../../Components/Modals/BasicModal";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";

import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";

import TextInput from "./../../../../../../../Components/Inputs/TextInput";
import usePouchCud from "../../../../../Hooks/usePouchCud";
import useApp from "../../../../../../../Hooks/useApp";
import AllContext from "../context/AllContext"
import useAxios from "../../../../../../../Hooks/useAxios";
import { useParams } from "react-router-dom";

const AddAllergiesModal = ({ showModal = false, setShowModal }) => {
  const input = useRef({ form: {}, err: {} });

  const [showError, setShowError] = useState(0);

  const request = useAxios();

  const {data, setData} = useContext(AllContext)

  const { user } = useApp();

  const { patient_id } = useParams();

  const { create } = usePouchCud();

  const { setModalMessage, setShowSuccessModal } = useApp();

  const formErrors = useHandleFormError();

  async function submit() {
    await setShowError(showError + 1);

    if (formErrors({ input })) return;
    
    let res = await request({
      method: "POST",
      url: "patient/allergy",
      data: {
        patient_id: parseInt(patient_id),
        alergy_type: input.current.form.alergy_type,
        alergen: input.current.form.alergen,
        reaction: input.current.form.reaction,
        user_id: parseInt(user.id),
      },
    });

    if (res !== "error") {
      await setModalMessage("Patient Allergy Added");
      await setShowSuccessModal(true);
      await create({ name: "patient_allergies", doc: res });

      let newData = data.map((datum) => (datum.id == res.id ? res : datum));
      setData(newData);
    }

    setShowModal(false);
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col p-5 gap-y-8 p-3" style={{ width: "30em" }}>
        <div style={{ marginTop: "-2.5em" }}>
          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="alergy_type"
            mt={0.5}
            config={{
              required: true,
              label: "Allergy Type",
              placeholder: "",
              maxChar: 250,
              minChar: 3,
            }}
          />
          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="alergen"
            mt={0.5}
            config={{
              required: true,
              label: "Allergen",
              placeholder: "",
              maxChar: 250,
              minChar: 3,
            }}
          />
          <TextInput
            initial={""}
            input={input}
            showError={showError}
            inputKey="reaction"
            mt={0.5}
            config={{
              required: true,
              label: "Reaction",
              placeholder: "",
              maxChar: 250,
              minChar: 3,
            }}
          />
        </div>
        <BtnRounded click={() => submit()}>Save</BtnRounded>
      </div>
    </BasicModal>
  );
};

export default AddAllergiesModal;
