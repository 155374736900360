import React, { useEffect, useRef, useState } from "react";
import BtnRounded from "../../../../../Components/Buttons/BtnRounded";
import BtnOutline from "../../../../../Components/Buttons/BtnOutline";
import { convDate, convDate2, convDate3 } from "../../../../../Util/Time";
import useApp from "../../../../../Hooks/useApp";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../Hooks/useAxios";
import PatientProfile from "./Tooltips/PatientDetail";
import ViewPatient from "./Modals/ViewPatient";
import PatientVitals from "./Tooltips/PaymentTip";
import ViewVitals from "./Modals/PaymentCollection";
import usePouch from "../../../../../Hooks/usePouch";
import VisitNotesTable from "./ConsultationBillTable/Table";
import InvestigationTable from "./InvestigationTable/Table";
import ProcedureTable from "./ProcedureTable/Table";
import PrescriptionTable from "./PrescriptionTable/Table";
import BtnPlain from "../../../../../Components/Buttons/BtnPlain";
import { FaArrowAltCircleRight, FaMoneyBill } from "react-icons/fa";
import PatientHistory from "./Tooltips/PatientHistory";
import ConsultationBillTable from "./ConsultationBillTable/Table";
import ProcedureBillsTable from "./ProcedureTable/Table";
import PaymentCollection from "./Modals/PaymentCollection";
import PaymentCollectionTip from "./Tooltips/PaymentTip";
import OtherItemsTable from "./OtherItemsTable/Table";
import InvoiceTip from "./Tooltips/InvoiceTip";
import Invoice from "../Invoice";
import { useReactToPrint } from "react-to-print";
import PatientComment from "./Tooltips/PatientComment";
import ViewComment from "./Modals/ViewComment";
import { toTZS } from "../../../../../Util/Currency";
import AddChargeModal from "./Modals/AddCharge";
import AddCharge from "./Modals/AddCharge";

const BillingDetails = ({
  patient,
  setpatient,
  consultations,
  prescriptions,
  investigations,
  procedures,
  commenter,
  othercharges,
  bills,
  billingDetails,
  getDetails,
  stepComments,
}) => {
  const [showComments, setShowComments] = useState(false);

  const [showPatient, setShowPatient] = useState(false);
  const [showVitals, setShowPaymentTip] = useState(false);
  const [showAddCharge, setShowAddCharge] = useState(false);
  const { id } = useParams();
  const request = useAxios();
  const { user, setModalMessage, setShowSuccessModal } = useApp();
  const { fetchById, findAll } = usePouch();
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState("none");
  const InvoiceRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => InvoiceRef.current,
  });

  return (
    <div className="p-6">
      <div className="flex justify-between mr-6 mb-20">
        <div className="space-y-4 ">
          <h2 className="font-medium text-2xl">Patient Details</h2>
          <div className="grid grid-cols-2 gap-8">
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                GENDER
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient.gender}
              </p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                PHONE
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient.phone != "null" ? patient.phone : "N/A"}
              </p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                EMAIL
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">{patient.email}</p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                OCCUPATION
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {patient.occupation}
              </p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                DATE OF BIRTH
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {convDate(parseInt(patient.dob))}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="">
            <h2 className="font-bold text-xl mb-4">
              GRAND TOTAL :{" "}
              {toTZS(parseInt(bills.total) + parseInt(bills.discount))}
            </h2>
          </div>
          <div className="">
            <h5 className="mb-2 text-xl font-medium inline-flex gap-x-2 tracking-tight text-cadet_grey ">
              TO PAY:
              <span>
                <p className="mb-3 font-normal text-gray-700 ">
                  {toTZS(bills.total)}
                </p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-2 text-xl font-medium inline-flex gap-x-2 tracking-tight text-cadet_grey ">
              AMOUNT PAID:
              <span>
                <p className="mb-3 font-normal text-gray-700 ">
                  {toTZS(bills.amount_paid)}
                </p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-2 text-xl font-medium inline-flex gap-x-2 tracking-tight text-cadet_grey ">
              Total Discount:
              <span>
                <p className="mb-3 font-normal text-gray-700 ">
                  {toTZS(bills.discount)}
                </p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-2 text-xl font-medium inline-flex gap-x-2 tracking-tight text-cadet_grey ">
              BALANCE:
              <span>
                <p className="mb-3 inline-flex gap-x-10  font-normal text-gray-700 ">
                  {toTZS(bills.total - bills.amount_paid)}
                  <span className="flex items-center cursor-pointer"></span>
                </p>
              </span>
            </h5>
          </div>
          <div className="flex flex-row gap-x-2 justify-start justify-items-end">
            <div className="flex">
              <PatientComment setShowComment={setShowComments} />
            </div>
            <div className="flex">
              <div style={{ display: isReady }}>
                <Invoice ref={InvoiceRef} props={billingDetails} />
              </div>

              <InvoiceTip handlePrint={handlePrint} />
            </div>
            <div className="flex">
              <PaymentCollectionTip setShowPaymentTip={setShowPaymentTip} />
            </div>
            <div className="flex">
              <PatientProfile setShowPatient={setShowPatient} />
            </div>
          </div>
        </div>
      </div>

      <ConsultationBillTable consultations={consultations} bills={bills} />
      <OtherItemsTable
        othercharges={othercharges}
        bills={bills}
        addChargeClick={() => setShowAddCharge(true)}
      />

      <ProcedureBillsTable procedures={procedures} bills={bills} />

      <PrescriptionTable prescriptions={prescriptions} bills={bills} />

      <InvestigationTable investigations={investigations} bills={bills} />

      <ViewPatient
        patient={patient}
        showModal={showPatient}
        setShowModal={setShowPatient}
        setpatient={setpatient}
      />

      <PaymentCollection
        bills={bills}
        showModal={showVitals}
        setShowModal={setShowPaymentTip}
        getDetails={getDetails}
      />
      <ViewComment
        stepComments={stepComments}
        showModal={showComments}
        setShowModal={setShowComments}
        commenter={commenter}
      />

      <AddChargeModal
        showModal={showAddCharge}
        setShowModal={setShowAddCharge}
        getDetails={getDetails}
      />

      <div className="mt-[8%] mb-[3%] flex justify-evenly items-center">
        <div className="">
          <BtnPlain
            bg="primary"
            textColor="white"
            activeBgColor="secondary"
            mt={2}
            click={() => {
              navigate(`/dashboard/next-step/${billingDetails.visit.id}`);
            }}
            className="btn"
          >
            <span className="px-2 font-light text-xl">
              <FaArrowAltCircleRight />
            </span>
            <span className=" font-pop-reg">Next Steps</span>
          </BtnPlain>
          {/* <BtnRounded onClick={SendToNext}>Next Steps</BtnRounded> */}
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;
