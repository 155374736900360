import React, { useEffect, useState } from 'react'

import { TableCell, TableText, TableRow } from '../../../../../../Components/FinalTable'

import usePouch from '../../../../../../Hooks/usePouch'

import { convDate } from '../../../../../../Util/Time'

import useApp from '../../../../../../Hooks/useApp'
import TableActions from './TableActions'
import Badge from '../../../../../../Components/Badges/Badge'

const TRow = ({ doc }) => {


  return (
    <>
      <TableRow key={doc.id}>
        
      <TableCell w={90 / 5}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={` ${doc.procedures.name}`} />
        </TableCell>
         <TableCell w={90 / 5}>
          <TableText label={doc.to_pay} />
        </TableCell>
        <TableCell w={90 / 5}>
          <TableText label={doc.payment_method} />
        </TableCell>
        <TableCell >
            <TableActions doc={doc}  />
          </TableCell>
      </TableRow>
    </>
  )
}

export default TRow