import React from "react";

import HeaderBtn from "../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import { TableHeaderRow } from "../../../../../../Components/FinalTable";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={90 / 6} label="DATE ADDED" />
        <HeaderBtn w={90 / 6} label="NAME" />
        <HeaderBtn w={90 / 6} label="DESCRIPTION" />
        <HeaderBtn w={90 / 6} label="TO PAY" />
        <HeaderBtn w={90 / 6} label="CREATED BY" />
      </TableHeaderRow>
    </div>
  );
};

export default THead;
