import React from 'react'


const TableActions = ({ doc}) => {

  

    return (
        <div className="flex-1">
            {/* <BtnPlain
                activeBgColor="white"
                bg="white"
                click={handleDelete}
            >
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                    <p className="  text-red">
                        <FaRegTrashAlt />
                    </p>
                </div>
            </BtnPlain> */}
        </div>
    )
}

export default TableActions